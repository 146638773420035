<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="800px" persistent scrollable>
      <v-card class="custom-text-size">
        <v-card-title>
          <span class="headline">
            Detalle del Visitante
          </span>
        </v-card-title>
        <v-card-text>
          <v-container >
            <div class="row">
              <div class="col-7">
                <div class="row">
                  <template v-if="model.attendee">
                    <div class="col-12">
                      <h2 class="text-capitalize">{{ model.attendee.fullName }}</h2>
                      <p>Nombre(s) y Apellido(s)</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-uppercase">{{ model.attendee.company }}</h2>
                      <p>Empresa</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-capitalize">{{ model.attendee.position }}</h2>
                      <p>Cargo</p>
                    </div>
                    <div class="col-12">
                      <h2>{{ model.attendee.phone }}</h2>
                      <p>Telefono</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-lowercase">{{ model.attendee.email }}</h2>
                      <p>Email</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-capitalize">{{ model.description }}</h2>
                      <p>Descripción</p>
                    </div>
                    <div class="col-6">
                      <h2 class="text-capitalize">{{ model.attendee.city }}</h2>
                      <p>Ciudad</p>
                    </div>
                    <div class="col-6">
                      <h2 class="text-capitalize">{{ model.attendee.country  }}</h2>
                      <p>Pais</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-capitalize">{{ model.attendee.category  }}</h2>
                      <p>Categoria</p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-12">
                      <h2 class="text-capitalize">{{ model.name }}</h2>
                      <p>Nombre(s) y Apellido(s)</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-uppercase">{{ model.companyName }}</h2>
                      <p>Empresa</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-capitalize">{{ model.companyPosition }}</h2>
                      <p>Cargo</p>
                    </div>
                    <div class="col-12">
                      <h2>{{ model.phone }}</h2>
                      <p>Telefono</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-lowercase">{{ model.email }}</h2>
                      <p>Email</p>
                    </div>
                    <div class="col-12">
                      <h2 class="text-capitalize">{{ model.description }}</h2>
                      <p>Descripción</p>
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-5">
                <v-avatar size="250" tile :key="model.id" v-if="model.imageUrl">
                  <v-img :key="model.id" :src="baseImageUrl + model.imageUrl"></v-img>
                </v-avatar>
                <v-avatar size="250" tile :key="model.id" v-else>
                  <v-img :key="model.id" src="@/assets/images/visitor-default.png"></v-img>
                </v-avatar>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import notificationMixin from '@/mixins/notificationMixin'
import loaderMixin from '@/mixins/loaderMixin'
import { GET_VISITOR_INFO_URL, API_MOBILE_IMAGES_URL } from '@/constants/ServicesMobileConstants'

export default {
  name: 'ExpositorModal',
  props: {
    fairId: {
      type: Number
    }
  },
  data () {
    return {
      baseImageUrl: API_MOBILE_IMAGES_URL,
      isActive: false,
      model: {}
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.model = {}
    },
    open (user) {
      this.showLoader()
      this.getMobile(GET_VISITOR_INFO_URL + user.id, { params: { fairId: this.fairId } })
        .then((response) => {
          this.model = response.data
          this.isActive = true
          this.hideLoader()
        }).catch((error) => {
          this.showError(error || 'Ha ocurrido un error')
          this.hideLoader()
        })
    }
  },
  mixins: [
    crudServiceMobileMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>
<style scoped>
  .custom-text-size{
    font-size: 14px !important;
  }
</style>
